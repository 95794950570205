import React, {useEffect, useState, useCallback} from "react";
import {Pressable, StyleSheet, Text, TextInput, View} from "react-native-web";
import {useNavigate} from "react-router-dom";
import {getCreatorProfile, saveCreator} from "../services/CreatorService";
import CustomCheckbox from "./CustomCheckbox";
import toast from "react-hot-toast";

const countryOptions = ['United States of America', 'Canada'];
const stateOptionsUS = ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'District of Columbia', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Puerto Rico', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'];
const stateOptionsCAN = ['Alberta', 'British Columbia', 'Manitoba', 'New Brunswick', 'Newfoundland and Labrador', 'Northwest Territories', 'Nova Scotia', 'Nunavut', 'Ontario', 'Prince Edward Island', 'Quebec', 'Saskatchewan', 'Yukon Territory'];
const notify = () => toast('Settings updated!', {
    duration: 2000,
    style: {
        fontFamily: "'lato', sans-serif",
    }
});


const ProfileSettings = () => {
    const navigate = useNavigate();
    const [isFeatureMeSelected, setFeatureMeSelected] = useState(false);
    const [isPhotoSelected, setPhotoSelected] = useState(false);
    const [isVideoSelected, setVideoSelected] = useState(false);
    const [isProductSelected, setProductSelected] = useState(false);
    const [isPaymentSelected, setPaymentSelected] = useState(false);
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [bio, setBio] = useState('');
    const [preferredContactEmail, setPreferredContactEmail] = useState('');
    const [featureMeClicked, setFeatureMeClicked] = useState(false);
    const [settingsChanged, setSettingsChanged] = useState(false)
    const [isLoading, setIsLoading] = useState(true);

    function CountryOptions() {
        return (
            countryOptions.map((country) => {
                return (<option key={country} value={country}>{country}</option>)
            })
        )
    }

    function StateOptions() {
        if (country === 'United States of America') {
            return (
                stateOptionsUS.map((state) => {
                    return (<option key={state} value={state}>{state}</option>)
                })
            )
        } else if (country === 'Canada') {
            return (
                stateOptionsCAN.map((state) => {
                    return (<option key={state} value={state}>{state}</option>)
                })
            )
        }
    }

    function handleFeatureMeClicked() {
        setFeatureMeSelected(!isFeatureMeSelected)
        setFeatureMeClicked(true)
    }

    function handlePhotoSelected() {
        setPhotoSelected(!isPhotoSelected)
        setSettingsChanged(true)
    }

    function handleVideoSelected() {
        setVideoSelected(!isVideoSelected)
        setSettingsChanged(true)
    }

    function handleProductSelected() {
        setProductSelected(!isProductSelected)
        setSettingsChanged(true)
    }

    function handlePaymentSelected() {
        setPaymentSelected(!isPaymentSelected)
        setSettingsChanged(true)
    }

    function handleCountryChanged(country) {
        setCountry(country)
        setSettingsChanged(true)
    }

    function handleStateChanged(state) {
        setState(state)
        setSettingsChanged(true)
    }

    function handleBioChanged(bio) {
        setBio(bio)
        setSettingsChanged(true)
    }

    function handlePreferredContactEmailChanged(email) {
        setPreferredContactEmail(email)
        setSettingsChanged(true)
    }

    const handleUpdate = useCallback(() => {
        const token = localStorage.getItem("token");
        const storedUser = JSON.parse(localStorage.getItem("user"));

        const request = {
            creatorId: storedUser.id,
            available: isFeatureMeSelected,
            productCompensation: isProductSelected,
            paymentCompensation: isPaymentSelected,
            photo: isPhotoSelected,
            video: isVideoSelected,
            country: country,
            provenance: state,
            bio: bio,
            preferredContactEmail: preferredContactEmail
        };

        saveCreator(request, token)
            .then(() => {
                setSettingsChanged(false);
                notify();
            }).catch(err => {
            console.error(err);
        });
    }, [
        isFeatureMeSelected,
        isProductSelected,
        isPaymentSelected,
        isPhotoSelected,
        isVideoSelected,
        country,
        state,
        bio,
        preferredContactEmail
    ]);

    //On page load
    useEffect(() => {
        let active = true;
        const token = localStorage.getItem("token");

        setIsLoading(true);  // Set loading to true when starting the fetch

        getCreatorProfile(token)
            .then(resp => {
                if (active && resp.status === 200 && resp.data) {
                    setFeatureMeSelected(resp.data.available)
                    setProductSelected(resp.data.productCompensation)
                    setPaymentSelected(resp.data.paymentCompensation)
                    setVideoSelected(resp.data.video)
                    setPhotoSelected(resp.data.photo)
                    setBio(resp.data.bio)
                    setCountry(resp.data.country)
                    setState(resp.data.provenance)
                    if (resp.data.preferredContactEmail) {  
                        setPreferredContactEmail(resp.data.preferredContactEmail)
                    } else {
                        const storedUser = JSON.parse(localStorage.getItem("user"));
                        setPreferredContactEmail(storedUser.email)
                    }
                }
            })
            .catch(err => {
                if (active && err.response.status === 401) {
                    localStorage.removeItem('user');
                    localStorage.removeItem('token');
                    navigate('/home');
                } else if (active && err.response.status === 400) {
                    console.log("No creator profile found")
                }
            })
            .finally(() => {
                if (active) {
                    setIsLoading(false);  // Set loading to false when fetch completes
                }
            });

        return () => {
            active = false;
        };
    }, [navigate]);

    //On change feature me
    useEffect(() => {
        if (!featureMeClicked) {
            return;
        }
        const token = localStorage.getItem("token");
        const storedUser = JSON.parse(localStorage.getItem("user"));

        const request = {
            creatorId: storedUser.id,
            available: isFeatureMeSelected,
            productCompensation: isProductSelected,
            paymentCompensation: isPaymentSelected,
            photo: isPhotoSelected,
            video: isVideoSelected,
            country: country,
            provenance: state,
            bio: bio,
            preferredContactEmail: preferredContactEmail
        };

        saveCreator(request, token)
            .then(resp => {
                console.log(resp)
            }).catch(err => {
            console.error(err)
        }).finally(() => {
            setFeatureMeClicked(false)
        })

    }, [
        featureMeClicked,
        isFeatureMeSelected,
        isProductSelected,
        isPaymentSelected,
        isPhotoSelected,
        isVideoSelected,
        country,
        state,
        bio,
        preferredContactEmail
    ]);

    if (isLoading) {
        return <View/>;
    }

    return (<View>
        <View style={{margin: 10}}>
            <Text style={styles.boldText}>Are you interested in connecting with brands for freelance content
                creation?</Text>
            <View style={styles.checkBoxContainer}>
                <View style={styles.checkBox}>
                    <CustomCheckbox
                        value={isFeatureMeSelected}
                        onValueChange={handleFeatureMeClicked}
                    />
                    <Text style={styles.text}> Yes, feature my Dupe profile on the Dupe Biz hiring page so brands
                        can find me</Text>
                </View>
            </View>
            <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                <Text style={styles.subtleText}>Requests will be sent to your email</Text>
                <View style={{flexDirection: 'row'}}>
                    <Text style={styles.subtleText}>by clicking yes, you are agreeing to our </Text>
                    <Text onClick={() => navigate('/license-and-terms')}
                          style={[styles.subtleText, {textDecorationLine: 'underline', cursor: 'pointer'}]}>Terms of
                        Service</Text>
                </View>
            </View>
        </View>
        {isFeatureMeSelected && <View>
            <View style={{flexDirection: 'row'}}>
                <View style={{flexDirection: 'column'}}>
                    <View style={{margin: 10}}>
                        <Text style={styles.boldText}>What type of content do you shoot?</Text>
                        <View style={styles.checkBoxContainer}>
                            <View style={styles.checkBox}>
                                <CustomCheckbox
                                    value={isPhotoSelected}
                                    onValueChange={handlePhotoSelected}
                                />
                                <Text style={styles.text}> Photo</Text>
                            </View>
                            <View style={styles.checkBox}>
                                <CustomCheckbox
                                    value={isVideoSelected}
                                    onValueChange={handleVideoSelected}
                                />
                                <Text style={styles.text}> Video</Text>
                            </View>
                        </View>
                    </View>

                    <View style={{margin: 10}}>
                        <Text style={styles.boldText}>What Country are you based in?</Text>
                        <select value={country} className={"Dropdown-root"} placeholder={"Select..."}
                                onChange={e => handleCountryChanged(e.target.value)}>
                            <option value={""} disabled/>
                            <CountryOptions/>
                        </select>
                    </View>
                </View>

                <View style={{flexDirection: 'column'}}>
                    <View style={{margin: 10}}>
                        <Text style={styles.boldText}>What type of compensation are you looking for?</Text>
                        <View style={styles.checkBoxContainer}>
                            <View style={styles.checkBox}>
                                <CustomCheckbox
                                    value={isProductSelected}
                                    onValueChange={handleProductSelected}
                                />
                                <Text style={styles.text}> Product</Text>
                            </View>
                            <View style={styles.checkBox}>
                                <CustomCheckbox
                                    value={isPaymentSelected}
                                    onValueChange={handlePaymentSelected}
                                />
                                <Text style={styles.text}> Payment</Text>
                            </View>
                        </View>
                    </View>

                    <View>
                        <View style={{margin: 10, maxWidth: 200}}>
                            <Text style={styles.boldText}>State</Text>
                            <select value={state} className={"Dropdown-root"} placeholder={"Select..."}
                                    onChange={e => handleStateChanged(e.target.value)}>
                                <option value={""} disabled/>
                                <StateOptions/>
                            </select>
                        </View>
                    </View>
                </View>
            </View>

            <View style={{margin: 10}}>
                <Text style={styles.boldText}>Preferred Contact Email</Text>
                <View style={[styles.checkBoxContainer, {flexDirection: 'column'}]}>
                    <TextInput
                        onChangeText={text => handlePreferredContactEmailChanged(text)}
                        value={preferredContactEmail}
                        style={{padding: 10, display: 'flex', color: 'black', fontFamily: "'lato', sans-serif"}}
                    />
                </View>
                <Text style={styles.subtleText}>If you prefer to be contacted via an email address other than your Dupe account email, enter it here.</Text>
            </View>

            <View style={{margin: 10}}>
                <Text style={styles.boldText}>Bio</Text>
                <View style={[styles.checkBoxContainer, {flexDirection: 'column'}]}>
                    <TextInput
                        multiline
                        rows={6}
                        maxLength={500}
                        onChangeText={text => handleBioChanged(text)}
                        value={bio}
                        style={{padding: 10, display: 'flex', color: 'black', fontFamily: "'lato', sans-serif"}}
                    />
                    <Text style={[styles.subtleText, {alignSelf: 'flex-end'}]}>
                        ({bio.length}/500)
                    </Text>
                </View>
                <Text style={styles.subtleText}>This is a great place to talk about what type of content you shoot,
                    your style, etc</Text>
            </View>

            <View style={{margin: 10}}>
                <Text style={styles.boldText}>Make sure your Instagram is added to your Dupe profile and set to a business or creator profile so brands can see your Instagram content.</Text>
            </View>

            <View>
                <Pressable style={!settingsChanged ? styles.updateButtonDisabled : styles.updateButton}
                           onPress={handleUpdate} disabled={!settingsChanged}>
                    <Text style={styles.updateButtonText}>Update</Text>
                </Pressable>
            </View>
        </View>}
    </View>)
}

const styles = StyleSheet.create({
    boldText: {
        fontWeight: 900,
        fontFamily: "'lato', sans-serif",
        marginBottom: 5,
    },
    subtleText: {
        color: '#A6A6A6',
        fontFamily: "'lato', sans-serif",
        marginTop: 5,
    },
    checkBoxContainer: {
        padding: 5,
        flexDirection: 'row',
        backgroundColor: '#F1F1F1',
        borderRadius: 5,
        border: '1px solid #396DE0'
    },
    checkBox: {
        flexDirection: 'row',
        margin: 5,
    },
    updateButton: {
        borderRadius: 20,
        backgroundColor: '#396DE0',
        minWidth: 100,
        margin: 5,
        alignSelf: 'flex-end'
    },
    updateButtonDisabled: {
        borderRadius: 20,
        backgroundColor: '#A6A6A6',
        minWidth: 100,
        margin: 5,
        alignSelf: 'flex-end',
        cursor: 'default'
    },
    updateButtonText: {
        color: 'white',
        fontWeight: 700,
        fontFamily: "'lato', sans-serif",
        alignSelf: 'center',
        fontSize: 14,
        padding: 10,
    },
    text: {
        fontFamily: "'lato', sans-serif",
    },
})

export default ProfileSettings